<template>
  <div 
      :style="{ backgroundColor: headerBackgroundColor }"
      :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      class="header"
  >
    <div v-if="focusedState" class="blurred" ></div>
    <div
      v-if="$route.name !== 'subscriberVerify'"
      class="container"
      :class="[mainHeaderClass ? 'header-content' : 'only-search']"
    >
      <div class="header-content-brand" id="smallHeader">
        <ul>
          <li v-if="hideIcons">
            <div class="logo" :class="localDisplayLang === 'ara' ? 'ml' : ''" 
                @click="routeHome()">
              <img
                :src="isThemeDark ? logoDark : logoLight"
                alt="ultra_logo"
                class="noorPlay_logo margin-top-10"
                @contextmenu.prevent="disableContextMenu"
                style="pointer-events: none; "
              />
            </div>
          </li>
          <li v-if="!hideOptions" class="desktop-only">
            <ul
              v-if="!hideMenuItems"
              :style="$PROVIDER_SETUP_CONFIG && $PROVIDER_SETUP_CONFIG.provider_name == 'ultra' ? 'margin-left: -2rem;' : ''"
              class="header-text list-items desktop-only scrollable-style"
            >
              <li v-for="(item, index) in menuList" :key="index" >
                <p
                  
                  :class="
                    routeName.toLowerCase() == (item.title.eng || item.title.default).toLowerCase()
                      ? 'active'
                      : ''
                  "
                  v-if="
                    !item.isRequiredLogin ||
                      (item.isRequiredLogin && subscriberid)
                  "
                  @click="menuSelect(index)"
                >
                  {{
                    localDisplayLang === "ml"
                      ?  item.title.mal || item.title.ar
                      : (item.title.eng || item.title.default).toLowerCase()
                  }}
                </p> 
              </li>
              <li>
                <div class="dropdown" v-if="(itemList.length > 0) && $PROVIDER_SETUP_CONFIG &&  $PROVIDER_SETUP_CONFIG.provider_name == 'ultra' ">
                  <img
                    
                    src="@/assets/icons/readMoreNew.svg"
                    alt="ultra_logo"
                    style="margin-top:12px"
                    @mouseover="hideDropdownList()"
                  />
                  <div class="dropdown-content" v-if="hideList">
                    <div style="padding-top: 12px;" ></div>
                    <li
                      class="list_items"
                      v-for="(item, index) in itemList"
                      :key="index"
                      v-if="!item.isRequiredLogin || (item.isRequiredLogin && subscriberid)"
                    >
                    
                    <router-link :to=moreMenuSelect(index)
                        :class="routeName.toLowerCase() == (item.title.eng || item.title.default).toLowerCase() ? 'active' : ''"
                        class="noSelect"
                      >
                      {{
                        localDisplayLang === "ml"
                        ?  item.title.mal || item.title.ar
                        : (item.title.eng || item.title.default).toLowerCase()
                      }}
                      </router-link>
                    </li>
                    <div style="padding-bottom: 12px;" ></div>
                  </div>
                </div>

              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="header-content-right justifyEnd">
        <div
          class="header-content-right-search"
          id="search-large"
          :class="[showHamBrands ? 'show-search' : 'show-search']"
          v-if="!hideOptions && searchFlagTwo"
        >
            <div class="header-content-right-search-content">
            <img
              class="header-content-right-search-content-back"
              :class="[showHamBrands ? 'no-back' : 'yes-back']"
              src="@/assets/icons/back.svg"
              @click="coverSmallSearch"
              alt
            />
            <input
              
              type="text"
              id="searchInput"
              class="color-Content"
              :placeholder="$t('searchPlaceHolder')"
              autocomplete="off"
              v-model="searchValue"
              @input="searchInputContentValue($event.target.value)"
            />
            <div
              @click="searchInputContent(searchValue)"
              id="searchIcon"
              class="searchIcon"
              alt
            >
            <smallSearch></smallSearch>
          </div>
          </div>
        </div>
        <div
          class="header-content-right-auth"
          :class="[showHamBrands ? 'flex-auth' : 'none-auth']"
          v-if="!hideOptions"
        >
          <div class="headerLogos">
            <div
              @click="showSmallSearch"
              v-if="searchFlagOne"
              class="py-10 ultra-lang-switch"
              id="noShowNotify"
            >
              <div tabindex="1" >
                <SearchMobile></SearchMobile>
              </div>
            </div>            
            <div
              class="py-10 ultra-lang-switch"
              id="noShowNotify"
              v-if="hideIcons"
              @click="actSwitchLanguage()"
            >
            <div tabindex="1" class="notify-icon">
            <LangSwitch></LangSwitch>
            </div>
            </div>
          </div>
          <div
            tabindex="1"
            class="header-content-right-auth-nav-btn desktop-only"
            v-if="!subscriberid && hideIcons"
            id="large-notify-info"
          >
            <button
              class="login-btn desktop-only"
              :class="[
                providerUniqueId === 'z01x1wPo'
                  ? 'primary-nammaflix-button'
                  : 'primary-net5-button',
              ]"
              @click="toggleAuthPopUp('lookup')"
            >
              {{ $t("LOGIN") }}
            </button>
          </div>

          <div
            tabindex="1"
            @click="
              toggleProfileDrop();
              rotate('arrow');
            "
            id="profile-container"
            class="header-content-right-auth-info"
            v-else
          >
            <ul
              class="header-content-logged-info"
              id="profile-ul-list"
              v-if="hideIcons"
            >
              <li id="noShowProfile">
                <div style="pointer-events: none;" id="noShowProfile-container">
                  <img
                    id="profile-icon"
                    class="profile-icon"
                    
                    :src="
                      subscriberProfileDetails
                        ? subscriberProfileDetails.picture
                        : subscriberDetails
                        ? subscriberDetails.picture
                        : null
                    "
                    v-if="
                      subscriberProfileDetails
                        ? subscriberProfileDetails.picture
                        : subscriberDetails
                        ? subscriberDetails.picture
                        : null
                    "
                    style="border-radius: 50%"
                    alt
                  />
                  <div
                    v-else
                    class="profile-icon"
                    id="profile-drop"
                    alt
                  >
                  <profileicon></profileicon>
                  </div> 
                </div>
              </li>
            </ul>
          </div>
          <div
            tabindex="1"
            class="header-content-right-auth-nav-btn  left-align"
            id="large-notify-info"
             v-if="((!isSubscribed && subscribeButtonCheck) && isSubscribeButtonEnabled)"
          >
            <button
              tabindex="1"
              class="button-primary"
              @click="subscribePopUp()"
            >
            {{ $t("SUBSCRIBE") }}
           </button>
          </div>
          <div
            v-if="showLanguageIcon && !isKidsModeOn && !subscriberid"
            style="margin-right:10px"
          >
            <div
              tabindex="1"
              class="py-10 ultra-lang-switch mobile-only"
              id="noShowNotify"
              v-if="hideIcons"
              @click="toggleAuthPopUp('lookup')"
            >
              <img src="@/assets/icons/profile.svg" alt />
            </div>
          </div>
        </div>
      </div>
      <div
        class="navMenuHeader navMenuMdMax mobile-only"
        id="headerMobilePhoneWise" v-if="headerVisible"
      >
        <div class="header-content-brand" id="smallHeader">
          <ul>
            <li v-if="!hideOptions">
              <ul v-if="!hideMenuItems" class="header-text list-items">
                <li v-for="(item, index) in mobileMenuList" :key="index">
                  <p
                    :class="routeName.toLowerCase() == (item.title.eng || item.title.default).toLowerCase() ? 'active' : ''"
                    v-if="
                      !item.isRequiredLogin ||
                        (item.isRequiredLogin && subscriberid)
                    "
                    @click="mobileMenuSelect(index)"
                  >
                    {{
                    localDisplayLang === "ml"
                      ?  item.title.mal || item.title.ar
                      : (item.title.eng || item.title.default).toLowerCase()
                  }}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <transition>
      <AuthPopup
        v-if="showAuthPopup"
        :formInfo="authInfo"
        :closePopup="() => closeAuthPopup(false)"
      />
    </transition>
    <transition>
      <!-- Error Popup -->
      <ErrorMessage
        v-if="showErrorMessagePopup"
        :messageData="messageData"
        :closePopup="
          () => toggleErrorMessagePopup({ state: false, messageData: null })
        "
      />
    </transition>
    <transition>
      <ForgotPassword
        v-if="showForgotPassword"
        :input="forgotPasswordInput"
        :inputtype="forgotPasswordInputtype"
        :closePopup="() => closeForgotPassword(false)"
      />
    </transition>
    <transition>
      <NotificationDrop v-if="isNotify" />
    </transition>
    <transition>
      <ProfileDrop v-if="isProfile" />
    </transition>
    <transition>
      <MoreDrop v-if="isMore" />
    </transition>
    <transition>
      <facebookEmail
        v-if="isFacebookEmail && facebookEmailPayload"
        :payload="facebookEmailPayload"
        :closePopup="() => toggleFacebookEmail(false)"
      />
    </transition>
    <transition>
      <sharedContent
        v-if="isSharedContent"
        :objectId="sharedContentId"
        :closePopup="() => toggleSharedContent(false)"
      />
    </transition>
    <transition>
      <switchProfilePin
        v-if="isSwitchProfile"
        :profile="profileInfo"
        :closePopup="() => toggleSwitchProfile(false)"
      />
    </transition>
    <transition>
      <payment
        v-if="isPayment"
        :paymentDetail="paymentDetail"
        :closePopup="() => togglePaymentPopup(false)"
      />
    </transition>
    <transition>
      <NoorPayment
        v-if="isNoorPayment"
        :paymentDetail="noorPaymentDetail"
        :closePopup="() => toggleNoorPayment(false)"
      />
    </transition>
    <transition>
      <checkout
        v-if="isCheckout"
        :checkoutDetails="checkoutPaymentDetails"
        :closePopup="() => toggleCheckoutPopup(false)"
      />
    </transition>
    <transition>
      <subscribe
        v-if="isSubscribe"
        :key="subscriberid"
        :subscribePayload="subscribepayload"
        :closePopup="() => toggleSubscribePopup(false)"
      />
    </transition>
    <transition>
      <mobileMenu
        :subscriptions="subscriptions"
        class="mobile-menu"
        v-if="isMobile"
        :menu="menuList"
        @close="closeMobileMenu"
      ></mobileMenu>
    </transition>
    <transition>
      <contentShare
        v-if="showshareContent"
        :content="shareContentDetails"
        :closePopup="() => toggleContentSharePopup(false)"
      ></contentShare>
    </transition>
    <!-- <transition>
      <overlayLight v-if="showOverlay"></overlayLight>
    </transition> -->
    <transition>
      <contentDetail
        v-if="isContentDetail"
        :content="contentDetailProp"
        :playerInstance="playerInstance"
        :closePopup="() => toggleContentDetail(false)"
      ></contentDetail>
    </transition>
    <transition>
      <telcoOtp
        v-if="isTelcoOTP"
        :paymentInfo="telcoPaymentInfo"
        :planInfo="telcoPlanInfo"
        :isMobileno="telcoMobileNumber"
        :gatewayInfo="telcoGatewayInfo"
        :closePopup="() => toggleTelcoOTP(false)"
      >
      </telcoOtp>
    </transition>

    <transition>
      <SubscriptionCancelReason
        v-if="isCancelReasonPopup"
        :closePopup="() => toggleCancelReasonPopup(false)"
      />
    </transition>

    <transition>
      <SubscriptionSuccessMessage
        :transactionResponse="transactionDetails"
        v-if="isShowSubscriptionPopup"
        :closePopup="() => toggleSuccessMessagePopup(false)"
      />
    </transition>
    <transition>
      <component
        :is="langLazyTemp"
        v-if="isLanguageSwitchEnabled"
        @cbSwitchLanguage="cbSwitchLanguage"
      ></component>
    </transition>
    <div v-if="showLoader" class="displayLoader">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import branchIO from "@/mixins/branchEvents.js";
import FirebaseActions from "@/mixins/FirebaseActions.js";
import moment from "moment";
import { _providerId } from "@/provider-config.js";
import firebase from "firebase";
import "firebase/messaging";
import { actGetSearchData, actGetPopulerList } from "@/modules/search";
export default {
  props: {
    showDeepPlan: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
    isAuth: {
      type: Boolean,
    },
  },
  data() {
    return {
      headerBackgroundColor: 'transparent',
      headerClass: true,
      isActive: "header-content",
      notActive: "only-search",
      dynamicSearchPlaceholder: "",
      isSmallSearch: true,
      searchCat: "All",
      filterCat: null,
      isShow: false,
      searchValue: "",
      showSearchDrop: false,
      showSearch: false,
      showAuthButtons: true,
      showAuthPopup: false,
      showForgotPassword: false,
      forgotPasswordDefaultId: null,
      isProfile: false,
      isMore: false,
      isNotify: false,
      activeTab: "active-tab",
      selectedSection: null,
      isFacebookEmail: false,
      forgotPasswordInput: "",
      forgotPasswordInputtype: "",
      subscriberDetails: null,
      authInfo: {},
      getSearch: [],
      isSharedContent: false,
      showshareContent: false,
      sharedContentId: null,
      shareContentDetails: null,
      isSwitchProfile: false,
      profileInfo: {},
      isPayment: false,
      paymentDetail: null,
      isSubscribe: false,
      subscribepayload: {},
      isMobile: false,
      hideSmallSearch: false,
      showOverlay: false,
      net5Logo: require("@/assets/icons/Net5-Logo.png"),
      showSmallBack: false,
      providerUniqueId: _providerId,
      isNoorPayment: false,
      noorPaymentDetail: null,
      isCheckout: false,
      checkoutPaymentDetails: null,
      isContentDetail: false,
      contentDetailProp: null,
      menuList: [],
      mobileMenuList: [],
      itemList: [],
      showHamBrands: true,
      mainHeaderClass: true,
      localDisplayLang: null,
      subscriptionDataMenu: null,
      enableLanguageOption: false,
      isEnabled: false,
      subscriptions: [],
      hideOptions: false,
      isTelcoOTP: false,
      telcoPaymentInfo: null,
      telcoGatewayInfo: null,
      telcoPlanInfo: null,
      telcoMobileNumber: false,
      showBellIcon: false,
      showLanguageIcon: true,
      notificationCount: 0,
      isCancelReasonPopup: false,
      subscriberProfileDetails: null,
      hideMenuItems: false,
      showErrorMessagePopup: false,
      messageData: null,
      isKidsModeOn: false,
      isSubscribed: false,
      subscribeButtonCheck: false,
      transactionDetails: null,
      isShowSubscriptionPopup: false,
      langLazyTemp: null,
      mainMenu: [],
      moreMenu: [],
      searchFlagOne: true,
      searchFlagTwo: false,
      hideIcons: true,
      isLanguageSwitchEnabled: false,
      hideList: true,
      focusedState: false,
      showLoader:false,
      temVal:'',
      logoLight: require("@/assets/darkcspacelogo.png"),
      logoDark: require("@/assets/cspace.png"),
      isThemeDark: true,
      headerVisible:true,
      isSubscribeButtonEnabled: false,
    };
  },
  computed: {
    ...mapGetters([
      "appConfig",
      "subscriberid",
      "getRtl",
      "subscriptionList",
      "isMovieEnabled",
      "profileid",
      "getPlanDeeplinkPath",
    ]),
    routeName() {
      let tempVar = this.$route.path;
      tempVar = tempVar.toLowerCase();
      if (tempVar.includes("movie")) {
        return "MOVIES";
      } else if (tempVar.includes("shows") || tempVar.includes("/series")) {
        return "SHOWS";
      } else if (tempVar.includes("songs") || tempVar.includes("music") || tempVar.includes('-video-songs')) {
        return "MUSIC";
      } else if (tempVar.includes("kids")) {
        return "KIDS";
      } else if (tempVar.includes("webseries")) {
        return "WEBSERIES";
      } else if (tempVar.includes("shorts")) {
        return "SHORTS";
      }  else if (tempVar.includes("watchlist")) {
        return "WATCHLIST";
      } else if (tempVar.includes("devotional")) {
        return "DEVOTIONAL";
      } else if (tempVar.includes("viewAllPlans")) {
        return "PLAN";
      } else if (tempVar.includes("search")) {
        //search home
        return "SEARCH";
      } else {
        return "HOME";
      }
    },

    getSubscriberProfileName() {
      if (!this.subscriberDetails) return;
      if (
        this.subscriberProfileDetails &&
        this.subscriberProfileDetails.profilename &&
        this.subscriberProfileDetails.profilename.length > 10 &&
        this.localDisplayLang === "eng"
      ) {
        return `${this.subscriberProfileDetails.profilename.substring(
          0,
          10
        )}...`;
      } else if (
        this.subscriberProfileDetails &&
        this.subscriberProfileDetails.profilename &&
        this.subscriberProfileDetails.profilename.length > 10 &&
        this.localDisplayLang === "ara"
      ) {
        return `...${this.subscriberProfileDetails.profilename.substring(
          0,
          10
        )}`;
      } else if (
        this.subscriberProfileDetails &&
        this.subscriberProfileDetails.profilename
      ) {
        return this.subscriberProfileDetails.profilename;
      } else if (
        this.subscriberDetails.profilename &&
        this.subscriberDetails.profilename.length > 10 &&
        this.localDisplayLang === "eng"
      ) {
        return `${this.subscriberDetails.profilename.substring(0, 10)}...`;
      } else if (
        this.subscriberDetails.profilename &&
        this.subscriberDetails.profilename.length > 10 &&
        this.localDisplayLang === "ara"
      ) {
        return `...${this.subscriberDetails.profilename.substring(0, 10)}`;
      } else {
        return this.subscriberDetails.profilename;
      }
    },
  },
  watch: {
    $route(to) {
      this.isProfile = false;
      let route = this.$router.currentRoute;

      if (
        route.name === "partner-switching" ||
        route.name == "telcoPaymentHandler" ||
        route.name == "addProfile" ||
        route.name == "switchProfile"
      ) {
        this.hideOptions = true;
      } else {
        this.hideOptions = false;
      }
    },
    subscriberid(val) {
      if (val) {
        this.getSubscriberDetails();
        this.getSubscriptionList();
      }
    },
    isMovieEnabled(val) {
      if (val) {
        // this.filterMovieMenu();
      }
    },
    screenWidth(val) {
      console.log("val", val);
    },
    subscriptionList(val) {
      if (val.length > 0) {
        // this.fetchDataItem();
      }
    },
    isProfile(value){
      if(value || this.isNotify){
        this.focusedState = true;
      }
      else{
        this.focusedState = false;
      }
    },
    isNotify(value){
      if(value || this.isProfile){
        this.focusedState = true;
      }
      else{
        this.focusedState = false;
      }
    }
  },
  created() {

    eventBus.$on("theme-change", (data) =>{
      if(data == 'theme-dark'){
        this.isThemeDark = true
      }else{
        this.isThemeDark = false
      }
    })
    let currentTheme = localStorage.getItem('theme')
    if(currentTheme == 'theme-light'){
      this.isThemeDark = false
    }else{
      this.isThemeDark = true
    }
    //	this.showMenu()
    if(this.$route.query.q) {
       let queryVal = this.$route.query.q;
       this.searchInputContent(queryVal);
    }
    this.searchValue = '';
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    if (firebase.messaging.isSupported()) {
      this.showBellIcon = true;
    }
    eventBus.$on("getNotificationsHeaderCount", () => {
      this.getNotificationCount();
    });
    eventBus.$on("search-close", () => {
      this.searchFlagOne = true;
      this.searchFlagTwo = false;
      this.searchValue = '';
    });
     eventBus.$on("enable_header", () => {
      this.coverSmallSearch();      
    });

    eventBus.$on("close-payment-popup", () => {
      this.togglePaymentPopup(false);
    });

    //disable header options if landing page.
    let route = this.$router.currentRoute;

    if (
      route.name == "partner-switching" ||
      route.name == "telcoPaymentHandler"
    ) {
      this.enableLanguageOption = true;
      this.isEnabled = true;
    } else if (screen.width < 576) {
      this.enableLanguageOption = false;
      this.isEnabled = false;
    } else {
      this.enableLanguageOption = true;
      this.isEnabled = true;
    }

    // disable header options if landing page.
    if (
      route.name === "partner-switching" ||
      route.name == "telcoPaymentHandler" ||
      route.name == "addProfile" ||
      route.name == "switchProfile"
    ) {
      this.hideOptions = true;
    }

    this.filterMenuItems();
    this.filterMoreMenuItems();
    this.fetchMenuList();

    if (
      this.subscriberProfileDetails &&
      this.subscriberProfileDetails.kidsmode === "YES"
    ) {
      this.isKidsModeOn = true;
    } else {
      this.isKidsModeOn = false;
    }

    if (this.subscriberid) {
      this.getSubscriptionList();
    }else {
      this.subscribeButtonCheck = true;
    }
    
    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    eventBus.$on("hideHeaderOptions", () => {
      console.log("hide header was", this.menuList);
      this.hideOptions = true;
      this.enableLanguageOption = true;
    });

    eventBus.$on("switchHeaderBack", () => {
      this.hideOptions = false;

      if (screen.width >= 1024) {
        this.enableLanguageOption = true;
      } else {
        this.enableLanguageOption = false;
      }
    });

    eventBus.$on("toggle-cancel-reason-popup", (data) => {
      this.toggleCancelReasonPopup(data);
    });
    eventBus.$on("toggle-auth", () => {
      this.toggleAuthPopUp('lookup');
    }); 
    eventBus.$on("achieveLogin", () => {
      this.toggleAuthPopUp('lookup');
    });

    this.getSubscriberDetails();

    if (this.showDeepPlan) {
      let payload = {
        listPlans: "ALL",
      };
      this.subscribepayload = payload;
      this.isSubscribe = true;
    }
    // window.addEventListener("resize", this.checkScreenWidth);

    // window.dispatchEvent(window.branchLoaded);
    window.dispatchEvent(window.facebookLoaded);
  },
  mounted() {
    if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isSubscribeButtonEnabled) {
      this.isSubscribeButtonEnabled = this.appConfig.featureEnabled.isSubscribeButtonEnabled
    }
    window.addEventListener('scroll', this.handleScroll);

    if (document.querySelector(".list-items p.active")) {
      document.querySelector(".list-items p.active").focus();
    }
    
    eventBus.$on("closeLangPopup", () => {
      this.isLanguageSwitchEnabled = false;
    });
    if (document.getElementById("headerMobilePhoneWise")) {
      let isMobileIphone = RegExp(/iPhone|iPod|iPad|Mac/i).test(
        navigator.userAgent
      );
    }
    if (this.isAuth && this.$router.currentRoute.name == "Home") {
      this.toggleAuthPopUp("lookup");
    }

    // Get new Notifications count
    this.getNotificationCount();

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    // this.actChangeMetaData(this.localDisplayLang);

    this.dynamicSearchPlaceholder = this.$t("Search Shows and Movies");

    this.deepLinking();

    // this.getBranchDeepLinks();

    eventBus.$on("switchProfile", (response) => {
      this.profileInfo = response;
      this.toggleSwitchProfile(true);
    });

    eventBus.$on("logout-success", () => {
      if (localStorage.getItem("loginType")) {
        let loginType = JSON.parse(localStorage.getItem("loginType"));
        if (loginType.type && loginType.type === "FB") {
          FB.logout(function(response) {
            // Person is now logged out
            console.log("facebook logout response", response);
          });
        }
      }
      //logging google event on successful logout.
      let GA = {
        status: "SUCCESS",
      };
      this.logoutEvent(GA);

      //calling utility function to clear localstorage details.
      this.logoutCB();
    });

    eventBus.$on("close-notification-drop", (state) => {
      // if (state) {
      this.toggleNotificationDrop(state);
      // }
    });

    eventBus.$on("showForgotPassword", (response) => {
      this.forgotPasswordInput = response.input;
      this.forgotPasswordInputtype = response.inputtype;
      this.toggleForgotPassword(true);
    });

    eventBus.$on("toggleSuccessMessagePopupTrigger", (response) => {
      // this.isShowSubscriptionPopup = true;
      this.toggleSuccessMessagePopup(true);
      this.transactionDetails = response;
    });

    eventBus.$on("toggleSuccessMessagePopupClose", (val) => {
      this.toggleSuccessMessagePopup(val);
    });

    eventBus.$on("close-forgot-password-popup", () => {
      this.closeForgotPassword(false);
    });

    eventBus.$on("facebookemail", (response) => {
      this.toggleFacebookEmail(true);
      this.facebookEmailPayload = response;
    });

    eventBus.$on("closeFacebookEmail", (val) => {
      this.toggleFacebookEmail(val);
    });

    eventBus.$on("paymentPopup", (response) => {
      this.paymentDetail = response;
      this.togglePaymentPopup(response.state);
    });

    eventBus.$on("noorPayments", (response) => {
      this.noorPaymentDetail = response;
      this.toggleNoorPayment(response.state);
    });

    eventBus.$on("showCheckout", (response) => {
      this.checkoutPaymentDetails = response;
      this.toggleCheckoutPopup(true);
    });

    eventBus.$on("showAuthorizeErrorMessage", (response) => {
      this.toggleErrorMessagePopup(response);
    });

    eventBus.$on("subscribePopup", (response) => {
      this.toggleSubscribePopup(true);
      this.subscribepayload = response;
    });

    eventBus.$on("showContentDetail", (response) => {
      this.contentDetailProp = response.content;
      this.toggleContentDetail(response.state);
    });

    eventBus.$on("telcoOtpPopup", (response) => {
      console.log("oreedo response in header", response);
      this.telcoPaymentInfo = response.paymentInfo;
      this.telcoPlanInfo = response.planInfo;
      this.telcoGatewayInfo = response.gatewayInfo;
      this.telcoMobileNumber = response.isMobileno;
      this.toggleTelcoOTP(response.state);
    });

    eventBus.$on("toggle-SearchInput", (state) => {
      this.showSearch = false;
      eventBus.$emit("show-light-overlay", false);
    });

    eventBus.$on("search-category-field", this.searchSelect);

    eventBus.$on("toggle-MenuDrop", (state) => {
      this.isShow = state;
    });

    eventBus.$on("toggle-profile-drop", (state) => {
      this.isProfile = state;
      if (document.getElementById("arrow")) {
        document.getElementById("arrow").classList.add("rotate-reverse");
      }
    });

    eventBus.$on("show-content-share", (response) => {
      this.showshareContent = response.popupState;
      this.shareContentDetails = response.content;
    });

    eventBus.$on("social-login-event", (response) => {
      let GA = {
        login_mode: response.app == "FB" ? "Facebook" : response.app,
        status: "Success",
      };

      const browser = this.getPlatformType();

      GA.platform = this.getPlatform();

      GA.platform_type = browser.browser;

      this.loginEvent(GA);
    });

    eventBus.$on("invoke-subscriber-details", (response) => {
      let data = {
        Name: response.profilename ? response.profilename : "",
        Identity: response.profileid ? response.profileid : "",
        Email: response.email ? response.email : "",
        Phone: response.mobileno ? response.mobileno : "",
        Gender: response.gender ? (response.gender === "MALE" ? "M" : "F") : "",
        DOB: response.dob ? moment(response.dob).format("DD-MM-YYYY") : "",
        Photo: response.picture ? response.picture : "",
        subscriber_id: response.subscriberid,
        "MSG-email": true,
        "MSG-push": true,
        "MSG-sms": true,
      };

      //clevertap user login sdk event.
      this.loginCleverEvent(data);

      let branch_login_data = {
        Subscriber_ID: response.subscriberid ? response.subscriberid : "",
        Name: response.profilename ? response.profilename : "",
        Gender: response.gender ? response.gender : "",
        Email: response.email ? response.email : "",
        Phone: response.mobileno ? parseInt(response.mobileno) : "",
        DOB: response.dob ? response.dob : "",
        Age: response.age ? parseInt(response.age) : "",
        Country: "IN",
      };

      //branch user login event
      this.loginBranchEvent(branch_login_data);
    });

    eventBus.$on("profile-switched", (response) => {
      let data = {
        Name: response.profilename ? response.profilename : "",
        Identity: response.profileid ? response.profileid : "",
        // "Email": "",
        // "Phone": "",
        Gender: response.gender ? response.gender : "",
        DOB: response.dob ? response.dob : "",
        Photo: response.picture ? response.picture : "",
        subscriber_id: response.subscriberid,
        "MSG-email": true,
        "MSG-push": true,
        "MSG-sms": true,
      };

      this.loginCleverEvent(data);
    });
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setRtl",
      "setSubscriptionList",
      "setSearchdataset",
      "setSubscriptionInformation"
    ]),

    ...mapActions(["listSubscription"]),
    cbSwitchLanguage() {
      this.isLanguageSwitchEnabled = false;
    },

    handleScroll() {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const scrollThreshold = 100;
        this.headerBackgroundColor = scrollY > scrollThreshold ? 'var(--clr-dynamic-header)' : 'var(--clr-mainhead-bg)';
      },

      disableContextMenu(event) {
      // Disable specific context menu options
      event.preventDefault();
      event.stopPropagation();
    },

    searchInputContentValue(value) {
      setTimeout(() => {
        this.debounce(() => this.searchInputContent())();
      }, 600);
    },
    debounce(func, timeout = 600) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    filterMenuItemsingDecking() {
      let dataset = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
      let menuItemsList = dataset.screens;

      let currentProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );

      if (currentProfileDetails) {
        currentProfileDetails = JSON.parse(currentProfileDetails);
        this.subscriberProfileDetails = currentProfileDetails;
      }

      const menuListArr = [];

      menuItemsList.forEach((menu) => {
     
        if (
            menu.profileTypes && menu.screenPosition == "TOP" &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))
          ) {
            menuListArr.push(menu);
            this.mobileMenuList.push(menu)


          }
        else if ( menu.profileTypes && menu.screenPosition == "MORE" &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))) {
                this.itemList.push(menu); 
                this.mobileMenuList.push(menu)

              } 
      });
      this.menuList = menuListArr;
    },
    filterMenuItems() {
       if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          this.filterMenuItemsingDecking();
          return;
      }
      let menuItemsList = this.appConfig.menu;

      let currentProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );

      if (currentProfileDetails) {
        currentProfileDetails = JSON.parse(currentProfileDetails);
        this.subscriberProfileDetails = currentProfileDetails;
      }

      const menuListArr = [];

      menuItemsList.forEach((menu) => {
        menuListArr.push(menu);
      });

      if (
        this.subscriptions &&
        this.subscriptions.length <= 0 &&
        this.profileid !== this.subscriberid
      ) {
        let menuIndex;


        menuListArr.forEach((menuItem, index) => {
          if (menuItem.id === "PLAN") {
            menuIndex = index;
          }
        });

        if (menuIndex > -1) {
          menuListArr.splice(menuIndex, 1);
        }
      }

      this.menuList = menuListArr;
      // this.itemList = menuListArr;

    },
    filterMoreMenuItems() {

      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
        // let dataset = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
        // this.itemList = dataset.moreMenuOptions ? dataset.moreMenuOptions : [];
      } else {
        let moreMenuItemsList = this.appConfig.moreMenuOptions;
        this.itemList = moreMenuItemsList;
      }

    },
    fetchMenuList() {
      this.mainMenu = this.appConfig.menu;
      this.moreMenu = this.appConfig.moreMenuOptions;
      if (!(this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled)) {
          this.mobileMenuList = this.appConfig.mobileMenuList;
      }
    },

    filterMovieMenu() {
      //checking if movie is available or not.
      this.menuList.forEach((el, index) => {
        if (this.isMovieEnabled === "No" && el.id === "MOVIES") {
          this.menuList.splice(index, 1);
        }
      });
    },

    getNotificationCount() {
      const notifications = JSON.parse(localStorage.getItem("notifications"));

      const deviceId = localStorage.getItem("random");

      let notificationsArr;

      if (notifications && this.subscriberid) {
        notificationsArr = notifications[this.subscriberid];
      } else if (notifications && deviceId) {
        notificationsArr = notifications[deviceId];
      }

      this.notificationCount = 0;

      if (notificationsArr && notificationsArr.length > 0) {
        notificationsArr.forEach((el) => {
          if (!el.isRead) {
            console.log("THE ELLLL", el);
            this.notificationCount++;
          }
        });
      }
    },

    getSubscriptionList() {
      this.subscriptions = [];
      let payload = {
        subscriptionstatus: "ALL",
      };
      this.listSubscription(payload)
        .then((response) => {
          this.subscribeButtonCheck = true;
          if(response.data.errorcode){
            if( response.data.errorode === 7109){
            this.isSubscribed = false;
            this.setSubscriptionInformation(false);
          }
          }
          else{
            this.isSubscribed = true;
            this.setSubscriptionInformation(true);

          }
          if (response.data.reason) {
            this.subscriptions = [];
            this.setSubscriptionInformation(false);

          } else {
            this.setSubscriptionInformation(true);

            this.subscriptions = response.data.data;


              let subscriptionDetails = response.data.data[0];
              let selected_quality = subscriptionDetails.quality;
              window.selected_Height_width = {}
              let qualityList = {
                'SD': '854:480',
                'HD': '1920:1080',
                'UHD1': '2560:1440',
                'UHD2': '3840:2160'
              };

              if (qualityList[selected_quality]) {
                window.selected_Height_width.height = qualityList[selected_quality].split(':')[1]
                window.selected_Height_width.width = qualityList[selected_quality].split(':')[0]

                localStorage.setItem("qualitylist", JSON.stringify(window.selected_Height_width))
              }


          }

          if (this.subscriberid && this.subscriptions.length != 0) {
            // this.fetchDataItem();
            this.menuList = this.menuList.filter((menu) => {
              if (menu.title.default !== "Subscriptions") {
                return menu;
              }
            });

          
            if (this.$router.currentRoute.fullPath === "/viewAllPlans") {
              this.$router.push("/");
            }
          } else {
            if (this.$router.currentRoute.fullPath === "/viewAllPlans") {
              if (this.getPlanDeeplinkPath) {
                this.$router.push(this.getPlanDeeplinkPath);
              }
            }
          }
        })
        .catch((error) => console.log(error));
    },
    changeLanguage() {
      console.log("change language from header---");
      this.$i18n.locale = this.$i18n.locale === "ara" ? "eng" : "ara";

      if (this.$router.currentRoute.name == "partner-switching") {
        console.log("localdisplaying language", this.localDisplayLang);
        if (this.localDisplayLang == "ara") {
          this.$router.push({
            name: "partner-switching",
            params: { lang: "en", id: this.$router.currentRoute.params.id },
          });
          this.setRtl(false);
        } else {
          this.$router.push({
            name: "partner-switching",
            params: { lang: "ar", id: this.$router.currentRoute.params.id },
          });
          this.setRtl(true);
        }
      } else if (this.$router.currentRoute.name === "telcoPaymentHandler") {
        if (this.localDisplayLang == "ara") {
          this.$router.push({
            name: "telcoPaymentHandler",
            params: {
              lang: "en",
              referenceid: this.$router.currentRoute.params.referenceid,
            },
          });
          this.setRtl(false);
        } else {
          this.$router.push({
            name: "telcoPaymentHandler",
            params: {
              lang: "ar",
              referenceid: this.$router.currentRoute.params.referenceid,
            },
          });
          this.setRtl(true);
        }
      } else {
        if (this.$i18n.locale === "eng") {
          this.setRtl(false);
        } else if (this.$i18n.locale === "ara") {
          this.setRtl(true);
        }
      }

      localStorage.setItem("setDisplayLanguageCode", this.$i18n.locale);
      this.localDisplayLang = this.$i18n.locale;

      this.actChangeMetaData(this.localDisplayLang);

      eventBus.$emit("check-local-storage", this.$i18n.locale);
      eventBus.$emit("check-for-series-route", this.$i18n.locale);
      eventBus.$emit("tc-pp", this.$i18n.locale);
      // eventBus.$emit("changeArabicCTAB");
      if (localStorage.getItem("isMobile")) {
        sessionStorage.setItem("isActiveDesktopView", true);
      }
      let currentUrl = window.location.href;
      if (this.$i18n.locale == "ara") {
        currentUrl = currentUrl.replace("/en", "/ar");
      } else {
        currentUrl = currentUrl.replace("/ar", "/en");
      }

      window.location.href = currentUrl;
    },
    routeHome() {
      if (this.$router.currentRoute.name === "Home") {
        eventBus.$emit("loadCategory", this.appConfig.menu[0]);
      } else if (this.$router.currentRoute.name === "switchProfile") {
        return;
      } else {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        this.$router.push({ name: "Home", params: { lang: currentLanguage } });
      }
    },
    actChangeMetaData(data) {
        document
          .getElementById("meta-desc")
          .setAttribute(
            "content",
            ""
          );
        document
          .getElementById("meta-key")
          .setAttribute(
            "content",
            ""
          );

  
        window.document.title = window.PROVIDER_SETUP_CONFIG.provider_Title
      
    },
    //storing input value in a variable
    inputSuggestions(event) {
      this.searchValue = event.target.value;
    },
    //branch initialization
    getBranchDeepLinks() {
      let self = this;
      branch.init("key_live_og9iwfe3yWP2nQHNfYVVOcbmCvaoMJd4", function(
        err,
        data
      ) {
        if (data && data.data_parsed["+is_first_session"] === true) {
          let medium = data.data_parsed["~channel"];

          let share = {
            Site: {
              AcquistionType: medium,
            },
          };

          clevertap.profile.push(share);
        }

        if (data && data.data_parsed.$desktop_deeplink_path) {
          let deeplinkPath = data.data_parsed.$desktop_deeplink_path;
          console.log("deeplinkPath", deeplinkPath);

          let getSlash = deeplinkPath.lastIndexOf("/");
          let lastItem = deeplinkPath.substring(getSlash + 1);
          console.log("lastItem", lastItem);

          let splittedDeeplink = [];
          splittedDeeplink = deeplinkPath.split("/");

          let deeplinkType = splittedDeeplink[splittedDeeplink.length - 2];
          console.log("deeplinkType", deeplinkType);
          if (deeplinkType === "details") {
            console.log("entered");
            // self.$router.push(`content/details/${lastItem}`);
            self.sharedContentId = lastItem;
            self.toggleSharedContent(true);
            console.log("ended");
          }
        }
        // self.$router.push("content/details/3yVYYkssUN1S");
      });
    },
    deepLinking() {
      //deep linking
      let currentroute = this.$router.currentRoute;

      if (currentroute && currentroute.params) {
        // share content
        if (
          currentroute.params &&
          currentroute.params.link == "content" &&
          currentroute.query.contentid &&
          currentroute.query.coupon == undefined
        ) {
          this.sharedContentId = currentroute.query.contentid;
          this.toggleSharedContent(true);
        }
      }
    },
    getSubscriberDetails() {
      // this.fetchMenuList();
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(
          localStorage.getItem("subscriberDetails")
        ).data;
      }
    },

    toggleCancelReasonPopup(value) {
      this.isCancelReasonPopup = value;

      console.log("THIS IS TOGGLE CANCEL POP UP", value);
    },

    showMenu() {
      //do click action based on different screen sizes.
      if (window.innerWidth < 992) {
        //this.isMobile = true;
      }
      // console.log("yes------->", this.isShow);
    },
    methodTohandleHover() {
      this.isMore = true;
    },
    hideDropdownList() {
      this.hideList = true;
    },
    closeMobileMenu(response) {
      this.isMobile = response.state;

      if (response.change) {
        setTimeout(() => {
          this.changeLanguage();
        }, 500);
      }
    },
    closeMenu() {
      this.showMenu();
    },
    // showMobileMenu() {
    //   this.isMobile = !this.isMobile;
    // },
    searchInputContent(queryVal) {
      let flag = '';
      if(queryVal) {
        flag = queryVal;
        this.searchValue = queryVal;
       }
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }
      let input = document.getElementById("searchInput") ? document.getElementById("searchInput").value : this.searchValue;
      //console.error("input=========>", input)
      if (input && input.length >= 3) {
        this.showLoader = true;
        this.showSearch = true;

        //eventBus.$emit("show-light-overlay", true);

        let validInput = input.toLowerCase();

        validInput = validInput.trim();


        let payload = {
          query: validInput,
          displaylanguage: this.localDisplayLang,
          page: 1,
        };

        // if (
        //   this.subscriberProfileDetails &&
        //   this.subscriberProfileDetails.kidsmode === "YES"
        // ) {
        //   payload.genre = "Kids";
        // }

        // eventBus.$emit("search-api-call", payload);
        actGetSearchData(payload).then(
          (res) => {
            console.log("res", res);
            this.showLoader = false;
            this.setSearchdataset(res.dataset);
            
          },
          (error) => {
            this.showLoader = false;
            console.log("error", error);
            this.setSearchdataset([]);
          }
        );

        //analytics detail object.
        let data = {
          Keyword: this.searchValue,
        };
        this.$router.push({path:'search',query:{q:this.searchValue}})

        //clevertap event.
        this.searchedCleverEvent(data);

        //google analytics Event.
        this.searchEvent(data);
        // this.showInputText(input);
        //console.log(this.searchValue);
      } else {
        this.showSearch = false;
        eventBus.$emit("show-light-overlay", false);
        this.setSearchdataset("");
      }
    },
    showInputText(val) {
      this.searchValue = val;
    },
    searchSelect(payload) {
      this.searchCat = payload.disCat;
      this.filterCat = payload.apiCat;
      this.showSearchDrop = false;
    },
    rotate(val) {
      if (this.isProfile || this.showSearchDrop) {
        document.getElementById(val).classList.remove("rotate-reverse");
        document.getElementById(val).classList.add("rotate");
      } else {
        document.getElementById(val).classList.remove("rotate");
        document.getElementById(val).classList.add("rotate-reverse");
      }
    },
    closeAuthPopup(state) {
      this.showAuthPopup = state;
      if (this.$route.name == "sainashorts") {
       
        setTimeout(() => {
          document.getElementById("body").style.overflowY = "hidden";

        }, 10)

      } else {
        document.getElementById("body").style.overflowY = "auto";
      }
    },

    toggleErrorMessagePopup(param) {
      this.showErrorMessagePopup = param.state;
      this.messageData = param.messageData;
      document.getElementById("body").style.overflowY = "auto";
    },
    closeForgotPassword(state) {
      this.showForgotPassword = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    loginSuccess(state) {
      this.showAuthButtons = state;
      this.closeAuthPopup(state);
    },
    logoutSuccess(state) {
      this.showAuthButtons = state;
    },
    toggleProfileDrop() {
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }
      this.isNotify = false;
    
      this.isProfile = !this.isProfile;
    },
    toggleSearchDrop() {
      this.showSearchDrop = !this.showSearchDrop;
    },
    toggleNotificationDrop(value) {
      if (value) {
        this.isLanguageSwitchEnabled = false;
      }
      this.isNotify = value;
      
    },
    actSwitchLanguage() {
      console.log("language | switch");
      document.body.style.overflow = "hidden"
      this.langLazyTemp = () =>
        import(
          /* webpackChunkName: "LanguageModel" */ "@/components/Language/LanguageModel.vue"
        );
        // setTimeout(() => {
          this.isLanguageSwitchEnabled = true;

        // }, 1000)
    },
    toggleAuthPopUp(state) {
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }
      this.showAuthPopup = true;
      this.authInfo.formType = state;
      setTimeout(() => {
        // eventBus.$emit("show-login-popup", state);
        document.getElementById("body").style.overflowY = "hidden";
      }, 5);
    },
    toggleForgotPassword(state) {
      this.showAuthPopup = false;
      this.showForgotPassword = state;
    },
    toggleFacebookEmail(state) {
      this.isFacebookEmail = state;
    },
    toggleSharedContent(state) {
      this.isSharedContent = state;
    },
    toggleSwitchProfile(state) {
      this.isSwitchProfile = state;
    },
    togglePaymentPopup(state) {
      console.log("toggle pYMENT POPUP", state);
      this.isPayment = state;
    },
    toggleNoorPayment(state) {
      this.isNoorPayment = state;
    },
    toggleCheckoutPopup(state) {
      this.isCheckout = state;
    },
    toggleSubscribePopup(state) {
      this.isSubscribe = state;
      //this.$router.push("en/viewAllPlans");
      //console.log("THIS IS SUB POP UP LOG ---", state);
    },
    toggleContentSharePopup(state) {
      //load component lazy.
      this.showshareContent = state;
    },
    toggleContentDetail(state) {
      this.isContentDetail = state;
    },
    toggleTelcoOTP(state) {
      this.isTelcoOTP = state;
    },
    showSmallSearch() {
      this.headerVisible = false
      eventBus.$emit("header-visible", false)
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }

      this.searchFlagTwo = true;
      this.searchFlagOne = false;
      if (screen.width < 576) {
        this.showHamBrands = true;
        this.hideIcons = false;
      }
      // this.$router.push("/search");

      let currentLanguage = this.getCurrentLanguageUrlBase();

     this.$router.push({name: 'SearchPage', params: {lang: currentLanguage}});

    },
    coverSmallSearch() {
      this.headerVisible = true;
      eventBus.$emit("header-visible", true)
      this.showHamBrands = true;
      this.mainHeaderClass = true;
      this.hideIcons = true;
      this.searchFlagTwo = false;
      this.searchFlagOne = true;
    },
    checkScreenWidth() {
      if (screen.width > 992) {
        if (this.showHamBrands) {
          this.showHamBrands = false;
          this.mainHeaderClass = true;
        }
      } else {
        this.coverSmallSearch();
      }
    },

    toggleSuccessMessagePopup(val) {
      if (val) {
        this.isShowSubscriptionPopup = true;
      } else if (!val) {
        this.isShowSubscriptionPopup = false;
      }
    },
    subscribePopUp() {
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }

      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
    },
    mobilePhoneOnlyMenuSelectDecking (index) {

      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }
      let currentLanguage = this.getCurrentLanguageUrlBase();
      let moreMenuDetail = this.mobileMenuList[index]
       let sectionTitle = moreMenuDetail.title.eng.replaceAll(' ','').toLowerCase();
    
      if (sectionTitle.includes('home')) {
        this.$router.push({
            name: "Home",
            params: { section: 'home', lang: currentLanguage },
          });
      } 
        else {
          this.$router.push({
            name: "section-screen",
            params: { section: sectionTitle, lang: currentLanguage },
          });
        }
    },
    mobileMenuSelect(index) {
      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          this.mobilePhoneOnlyMenuSelectDecking(index);
          return;
      }
      console.log("THE MENU LIST --- > ", index, this.mobileMenuList[index]);
      let currentLanguage = this.getCurrentLanguageUrlBase();
      // this.selectedSection = index;
      let mobMenuDetail = this.mobileMenuList[index];

      let lowerCaseSection = (mobMenuDetail.id).toLowerCase()


      switch (mobMenuDetail.id) {
        case "HOME":
          this.$router.push({
            name: "Home",
            params: { mobMenuDetail, lang: currentLanguage },
          });
          break;
        case "SERIES":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "SHOWS":
          // this.$router.push({ name: "series", params: { mobMenuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "MOVIES":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "MUSIC":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
          case "WEBSERIES":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
          case "SHORTS":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          // this.$router.push({
          //   name: "Songs",
          //   params: { section: mobMenuDetail.id, lang: currentLanguage },
          // });
          break;
        case "WATCHLIST":
          this.$router.push({
            name: "Watchlist",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        // case "WATCHLIST":
        //   this.$router.push({ name: "Watchlist", params: { menuDetail, lang: currentLanguage } });
        //   break;
        case "PLAN":
          this.$router.push({
            name: "seeAllPlans",
            params: { mobMenuDetail, lang: currentLanguage },
          });

          break;
        case "HOME-KIDS":
          this.$router.push({ name: "Home", params: { mobMenuDetail } });
          break;
      }
      this.hideIcons = true;
    },
    menuSelectDecking (index) {
      // if (this.isLanguageSwitchEnabled == true) {
      //   this.isLanguageSwitchEnabled = false;
      // }
      let currentLanguage = this.getCurrentLanguageUrlBase();
      let moreMenuDetail = this.menuList[index]
       let sectionTitle = moreMenuDetail.title.eng.replaceAll(' ','').toLowerCase();
      //  this.hideList = false;

       if (sectionTitle == 'home') {
        this.$router.push({
            name: "Home",
            params: { lang: currentLanguage },
          });
       }  else {
          this.$router.push({
            name: "Home",
            params: { section: sectionTitle, lang: currentLanguage },
          });
       
       }
   

 
    },
    menuSelect(index) {
      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
         return this.menuSelectDecking(index);
          // return;
      }
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }
      console.log("THE MENU LIST --- > ", index, this.menuList[index]);
      let currentLanguage = this.getCurrentLanguageUrlBase();
      // this.selectedSection = index;
      let menuDetail = this.menuList[index];
      let lowerCaseSection = (menuDetail.id).toLowerCase()
      switch (menuDetail.id) {
        case "HOME":
          this.$router.push({
            name: "Home",
            params: { menuDetail, lang: currentLanguage },
          });
          break;
        case "SERIES":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "SHOWS":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "MOVIES":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "SONGS":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "DEVIOTIONAL":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;

          case "DEVOTIONAL":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "SHORTBYTES":
          // this.$router.push({ name: "series", params: { menuDetail } });
          // this.$router.push({ name: "section-screen", params: { section: moreMenuDetail.id, lang: currentLanguage } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "KIDS":
          // this.$router.push({ name: "kids", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
          break;
        case "WATCHLIST":
          this.$router.push({
            name: "Watchlist",
            params: { menuDetail, lang: currentLanguage },
          });
          break;
        case "PLAN":
          let payload = { listType: "onlySubscribe" };
          localStorage.setItem("payment_source", "Menu");
          // eventBus.$emit("subscribePopup", payload);
          // this.$router.push("/viewAllPlans");
          this.$router.push({
            name: "seeAllPlans",
            params: { menuDetail, lang: currentLanguage },
          });

          break;
        case "HOME-KIDS":
          this.$router.push({ name: "Home", params: { menuDetail } });
          break;
         default: 
         this.$router.push({
            name: "section-screen",
            params: { section: lowerCaseSection, lang: currentLanguage },
          });
      }
      this.hideIcons = true;
    },
    moreMenuSelectDecking(index) {

      // if (this.isLanguageSwitchEnabled == true) {
      //   this.isLanguageSwitchEnabled = false;
      // }
      let currentLanguage = this.getCurrentLanguageUrlBase();
      // this.hideList = false;

      let moreMenuDetail = this.itemList[index]
       let sectionTitle = moreMenuDetail.title.eng.replaceAll(' ','').toLowerCase();

        if (sectionTitle.includes('songs') || sectionTitle.includes('music')) {
          return({
            name: "section-screen-video",
            params: { section: 'music', lang: currentLanguage },
          });
        } else if (sectionTitle.includes('short bytes') || sectionTitle.includes('shortbytes')) {
          return({
            name: "section-screen-bytes",
            params: { section: 'shortbytes', lang: currentLanguage },
          });
        } else if ( sectionTitle.includes('devotional')) {
          return({
            name: "section-screen-devotional",
            params: { section: 'devotional', lang: currentLanguage },
          });
        } else {
          return({
            name: "section-screen",
            params: { section: sectionTitle, lang: currentLanguage },
          });
        }
  
 
    },
    moreMenuSelect(index) {
      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
         return this.moreMenuSelectDecking(index);
          // return;
      }
      // if (this.isLanguageSwitchEnabled == true) {
      //   this.isLanguageSwitchEnabled = false;
      // }
      console.log("THE MoMENU LIST --- > ", index, this.itemList[index]);
      let currentLanguage = this.getCurrentLanguageUrlBase();
      // this.selectedSection = index;
      // let moreMenuDetail = this.itemList[index];
      let moreMenuDetail = this.itemList[index]

       this.$router.push({
            name: "section-screen",
            params: { section: moreMenuDetail.id.toLowerCase(), lang: currentLanguage },
          });
      switch (moreMenuDetail.id) {
        case "SONGS":
          this.$router.push({
            name: "section-screen-video",
            params: { section: moreMenuDetail.id.toLowerCase(), lang: currentLanguage },
          });
          break;
        case "SHORTBYTES":
        this.$router.push({
            name: "section-screen-bytes",
            params: { section: moreMenuDetail.id.toLowerCase(), lang: currentLanguage },
          });
          break;
        // 		case "SONGS":
        //       // this.$router.push({ name: "movies", params: { menuDetail } });
        //       this.$router.push({ name: "section-screen", params: { section: moreMenuDetail.id, lang: currentLanguage } });
        // //this.$router.push({ name: "Home", params: {section:moreMenuDetail.id, lang: currentLanguage } })
              // break;
        case "DEVOTIONAL":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          // this.$router.push({ name: "section-screen", params: { section: moreMenuDetail.id, lang: currentLanguage } });
          this.$router.push({
            name: "section-screen-devotional",
            params: { section: moreMenuDetail.id.toLowerCase(), lang: currentLanguage },
          });
          // this.$router.push({
          //   name: "Devotional",
          //   params: { section: moreMenuDetail.id, lang: currentLanguage },
          // });
          break;
        case "KIDS":
          // this.$router.push({ name: "kids", params: { menuDetail } });
          this.$router.push({
            name: "section-screen",
            params: { section: moreMenuDetail.id.toLowerCase(), lang: currentLanguage },
          });
          // this.$router.push({
          //   name: "Kids",
          //   params: { section: moreMenuDetail.id, lang: currentLanguage },
          // });
          break;
        case "WATCHLIST":
          this.$router.push({
            name: "Watchlist",
            params: { section: moreMenuDetail.id.toLowerCase(), lang: currentLanguage },
          });
          break;
        case "PLAN":
          let payload = { listType: "onlySubscribe" };
          localStorage.setItem("payment_source", "Menu");
          // eventBus.$emit("subscribePopup", payload);
          // this.$router.push("/viewAllPlans");
          this.$router.push({
            name: "seeAllPlans",
            params: { moreMenuDetail, lang: currentLanguage },
          });

          break;
        case "HOME-KIDS":
          this.$router.push({ name: "Home", params: { menuDetail } });
          break;
      }
      this.hideList = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    eventBus.$off("toggle-auth");
    eventBus.$off("switchProfile");
    eventBus.$off("logout-success");
    eventBus.$off("showForgotPassword");
    eventBus.$off("facebookemail");
    eventBus.$off("closeFacebookEmail");
    eventBus.$off("paymentPopup");
    eventBus.$off("noorPayments");
    eventBus.$off("showCheckout");
    eventBus.$off("subscribePopup");
    eventBus.$off("showContentDetail");
    eventBus.$off("telcoOtpPopup");
    eventBus.$off("toggle-profile-drop");
    eventBus.$off("show-content-share");
    eventBus.$off("toggle-SearchInput");
    eventBus.$off("getNotificationsHeaderCount");
    eventBus.$off("toggle-cancel-reason-popup");
    eventBus.$off("close-forgot-password-popup");
    eventBus.$off("close-payment-popup");
  },
  components: {
    AuthPopup: () =>
      import(
        /* webpackChunkName: "header" */ "@/components/Popups/Authentication/userAuth.vue"
      ),
      Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    ErrorMessage: () =>
      import(
        /* webpackChunkName: "header" */ "@/components/Popups/message.vue"
      ),
    ForgotPassword: () =>
      import(
        /* webpackChunkName: "fgtpwd" */ "@/components/Popups/ForgotPassword.vue"
      ),
    Menu: () =>
      import(/* webpackChunkName: "menu" */ "@/components/Templates/Menu.vue"),
    Search: () =>
      import(
        /* webpackChunkName: "search" */ "@/components/Templates/Search.vue"
      ),
    SearchDrop: () =>
      import(
        /* webpackChunkName: "search" */ "@/components/Templates/SearchDropdown.vue"
      ),
    MoreDrop: () =>
      import(
        /* webpackChunkName: "moreDrop" */ "@/components/Templates/moreOptions.vue"
      ),
    ProfileDrop: () =>
      import(
        /* webpackChunkName: "profileDrop" */ "@/components/Templates/profile.vue"
      ),
    NotificationDrop: () =>
      import(
        /* webpackChunkName: "notificationDrop" */ "@/components/Templates/notification.vue"
      ),
    facebookEmail: () =>
      import(
        /* webpackChunkName: "facebookEmail" */ "@/components/Popups/facebookemail.vue"
      ),
    sharedContent: () =>
      import(
        /* webpackChunkName: "facebookEmail" */ "@/components/Popups/sharedContent.vue"
      ),
    payment: () =>
      import(
        /* webpackChunkName: "payment" */ "@/components/Popups/Payment/Payment.vue"
      ),
    NoorPayment: () =>
      import(
        /* webpackChunkName: "Noor Payment" */ "@/components/Popups/NoorPayments/payment.vue"
      ),
    subscribe: () =>
      import(
        /* webpackChunkName: "subscribe" */ "@/components/Popups/Subscribe.vue"
      ),
    mobileMenu: () =>
      import(
        /* webpackChunkName: "mobilemenu" */ "@/components/Templates/mobileMenu.vue"
      ),
    contentShare: () =>
      import(
        /* webpackChunkName: "contentshare" */ "@/components/Popups/contentShare.vue"
      ),
    overlayLight: () =>
      import(
        /* webpackChunkName: "overlayLight" */ "@/components/Overlay/Overlay.vue"
      ),
    checkout: () =>
      import(
        /* webpackChunkName: "checkout" */ "@/components/gateways/checkout.vue"
      ),
    contentDetail: () =>
      import(
        /* webpackChunkName: "contentDetail" */ "@/components/Popups/contentDetail.vue"
      ),
    telcoOtp: () =>
      import(
        /* webpackChunkName: "telcoOtp" */ "@/components/Popups/telcoOtp.vue"
      ),
    SubscriptionCancelReason: () =>
      import(
        /* webpackChunkName: "telcoOtp" */ "@/components/Popups/SubscriptionCancelReason.vue"
      ),
    SubscriptionSuccessMessage: () =>
      import(
        /* webpackChunkName: "SubscriptionSuccessMessage" */ "@/components/Popups/SubscriptionSuccessMessage.vue"
      ),
      LangSwitch: () => import(/* webpackChunkName: "switchlang" */ "@/components/SvgImages/langSwitch.vue"),
      NotiSwitch: () => import(/* webpackChunkName: "notificatio" */ "@/components/SvgImages/Notificationbell.vue"),
      SearchMobile: () => import(/* webpackChunkName:"searchicon" */ "@/components/SvgImages/SearchMobile.vue"),
      smallSearch: () => import(/* webpackChunkName: "smallsearchicon" */ "@/components/SvgImages/SmallSearch.vue"),
      profileicon: () => import(/* webpackChunkName: "profileicon" */ "../SvgImages/Profilesvg.vue"),
  },
  mixins: [Utility, googleAnalytics, cleverTap, branchIO, FirebaseActions],
};
</script>
<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./Header.scss"
</style>
